import React from 'react';
import {
  HTMLMotionProps,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';
import { Image, ImageField, LinkField } from '@sitecore-jss/sitecore-jss-react';
import {
  useBreakpoints,
  useResponsiveValue,
  ResponsiveValue,
  RoutableSitecoreLink,
} from '@achmea/ui';

const getImageSize = (size: number) => {
  const { isMd, isXl } = useBreakpoints();

  const screenSize = isXl ? 1680 : isMd ? 768 : 375;

  const result = (size / screenSize) * 100;

  return result;
};

const useOverlayImageTransform = (
  image: OverlayImage,
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const from = useResponsiveValue(image.from);
  const to = useResponsiveValue(image.to);
  const exit = useResponsiveValue(image.exit);

  const translateX = useTransform(progress, keyframes, [
    `${from.x}vw`,
    `${to.x}vw`,
    `${to.x}vw`,
    `${exit.x}vw`,
  ]);
  const translateY = useTransform(progress, keyframes, [
    `${from.y}vh`,
    `${to.y}vh`,
    `${to.y}vh`,
    `${exit.y}vh`,
  ]);
  const width = useTransform(progress, keyframes, [
    `${getImageSize(from.width)}vw`,
    `${getImageSize(to.width)}vw`,
    `${getImageSize(to.width)}vw`,
    `${getImageSize(exit.width)}vw`,
  ]);
  const height = useTransform(progress, keyframes, [
    `${getImageSize(from.height)}vw`,
    `${getImageSize(to.height)}vw`,
    `${getImageSize(to.height)}vw`,
    `${getImageSize(exit.height)}vw`,
  ]);

  return { translateX, translateY, width, height };
};

interface Position {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface OverlayImage {
  id: string;
  image: ImageField;
  link: LinkField;
  from: Partial<ResponsiveValue<Position>>;
  to: Partial<ResponsiveValue<Position>>;
  exit: Partial<ResponsiveValue<Position>>;
}

export interface ScrollLinkedOverlayImageLayerProps
  extends HTMLMotionProps<'div'> {
  progress: MotionValue<number>;
  keyframes: number[];
  overlayImage: OverlayImage;
}

const ScrollLinkedOverlayImageLayer = (
  props: ScrollLinkedOverlayImageLayerProps,
) => {
  const {
    overlayImage,
    progress,
    keyframes,
    className = '',
    style,
    ...restProps
  } = props;

  if (keyframes.length !== 4) {
    throw new Error(
      'Length of ScrollLinkedOverlayImageLayer keyframes does not match output animation length',
    );
  }

  const { translateX, translateY, width, height } = useOverlayImageTransform(
    overlayImage,
    progress,
    keyframes,
  );

  return (
    <motion.div
      className={`scroll-linked-overlay-images-composition__image-container ${className}`}
      style={{
        ...style,
        translateX,
        translateY,
        width,
        height,
      }}
      {...restProps}
    >
      <RoutableSitecoreLink
        field={overlayImage.link}
        editable={false}
        target="_blank"
        className="scroll-linked-overlay-images-composition__link"
      >
        <div className="scroll-linked-overlay-images-composition__image-wrapper">
          <Image
            className="scroll-linked-overlay-images-composition__image"
            field={overlayImage.image}
            srcSet={[{ mw: 600 }]}
            sizes="600px"
          />
        </div>
      </RoutableSitecoreLink>
    </motion.div>
  );
};

export default ScrollLinkedOverlayImageLayer;
