import { ImageField, ImageFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { BouncingImageCompositionProps } from '../../scrollytelling/BouncingImageComposition/BouncingImageComposition';
import { OverlayImagesCompositionProps } from '../../scrollytelling/OverlayImagesComposition/OverlayImagesComposition';
import { AnimatedLogos } from '../../../components/HomePageLayout';

export const statementMotionPath = {
  from: {
    DEFAULT: {
      // Margin left and right are equal, resulting in 50
      x: 50,
      /**
       * Top: 419px
       * Height statement1: 192px
       * Screen height: 945px
       *
       * Calculation: (top + height / 2) / screen height * 100
       */
      y: 118.7,
      shiftX: -50,
      shiftY: 0,
    },
  },
  to: {
    DEFAULT: {
      x: 50,
      y: 54.5,
      shiftX: -50,
      shiftY: -50,
    },
  },
  exit: {
    DEFAULT: {
      x: 50,
      y: 41.7,
      shiftX: -50,
      shiftY: -100,
    },
  },
};

export type RoundedImagePosition = 'left' | 'right';

export const firstRoundedImageMotionPath = {
  from: {
    DEFAULT: {
      x: 16,
      y: 134.3,
      shiftX: -50,
      shiftY: -50,
    },
  },
  to: {
    DEFAULT: {
      x: 16,
      y: 54.5,
      shiftX: -50,
      shiftY: -50,
    },
  },
  exit: {
    DEFAULT: {
      x: 16,
      y: -52.1,
      shiftX: -50,
      shiftY: -50,
    },
  },
};

export const secondRoundedImageMotionPath = {
  from: {
    DEFAULT: {
      x: 84.1,
      y: 134.3,
      shiftX: -50,
      shiftY: -50,
    },
  },
  to: {
    DEFAULT: {
      x: 84.1,
      y: 54.5,
      shiftX: -50,
      shiftY: -50,
    },
  },
  exit: {
    DEFAULT: {
      x: 84.1,
      y: -52.1,
      shiftX: -50,
      shiftY: -50,
    },
  },
};

export const cardSliderMotionPath = {
  from: {
    DEFAULT: {
      x: 0,
      y: 100,
    },
  },
  to: {
    DEFAULT: {
      x: 0,
      y: 56.7,
      shiftY: -50,
    },
  },
  exit: {
    DEFAULT: {
      x: 0,
      y: 0,
      shiftY: -100,
    },
  },
};

const scrollLinkedBouncingImagePositions = [
  {
    position: {
      DEFAULT: { x: -40, y: 14, height: 315, width: 315 },
      xl: { x: 10.6, y: 19.5, height: 337, width: 337 },
    },
  },
  {
    position: {
      DEFAULT: { x: 7.5, y: 12.2, height: 335, width: 335 },
      xl: { x: 40.5, y: 13.3, height: 317, width: 317 },
    },
  },
  {
    position: {
      DEFAULT: { x: 50, y: 16.2, height: 298, width: 298 },
      xl: { x: 63.6, y: 17.1, height: 409, width: 409 },
    },
  },
];

export const getScrollLinkedBouncingImageProps = (
  imagesData: (ImageField | ImageFieldValue)[],
) => {
  return {
    images: imagesData.map((image, index) => ({
      id: `${index + 1}`,
      image: image,
      position: scrollLinkedBouncingImagePositions[index].position,
    })),
  } as BouncingImageCompositionProps;
};

const scrollLinkedImagesOverlayPositions = [
  /**
   * To calculate the values for the x and y values, follow these steps:
   * 1. Go to the figma file `4. Templates` > `09. Home Page`
   * 2. Select the screen before the images are visible on screen
   * 3. Search in the left window (below the pages section) which screen you have selected
   * 4. Open this and search inside for the images (most are named SubLogo)
   * 5. Check in developer mode the Layout section, positioned in the right window
   * 6. Write down the value in `top` or `bottom` and `left` or `right`
   *    (this depends on the place of origin the image appears from)
   * 7. Repeat step 3 till 6 for the screen where the images are visible on the screen
   * 8. Check the Avero example below for the calculations
   */
  // Avero
  {
    from: {
      DEFAULT: {
        // Screen size mobile => 375x650
        // Calculation: (left / 375) * 100
        x: 6.4,
        // Calculation: (top / 650) * 100
        y: 104.4,
        height: 80,
        width: 80,
      },
      // Uses sizes sm and positions xl
      lg: {
        x: 9.5,
        y: -23,
        height: 80,
        width: 80,
      },
      xl: {
        // Screen size desktop => 1680/945
        // Calculation: (left / 1680) * 100
        x: 9.5,
        // Calculation: (top / 945) * 100
        y: -23,
        height: 175,
        width: 175,
      },
    },
    to: {
      DEFAULT: {
        x: 7.5,
        y: 11.2,
        height: 102,
        width: 102,
      },
      lg: {
        x: 22,
        y: 15.2,
        height: 102,
        width: 102,
      },
      xl: {
        x: 22,
        y: 15.2,
        height: 175,
        width: 175,
      },
    },
    exit: {
      DEFAULT: {
        x: 4.8,
        y: -63,
        height: 102,
        width: 102,
      },
      lg: {
        x: 22,
        y: -119,
        height: 102,
        width: 102,
      },
      xl: {
        x: 22,
        y: -119,
        height: 175,
        width: 175,
      },
    },
  },
  // Centraal beheer
  {
    from: {
      DEFAULT: {
        x: 32.8,
        y: 121,
        height: 173,
        width: 173,
      },
      lg: {
        x: 38,
        y: -53,
        height: 173,
        width: 173,
      },
      xl: {
        x: 38,
        // Note: Original value was -33, but on screenheight of 810px and large screenwidth,
        // this would be seeable on ScrollHeroBlock
        y: -53,
        height: 300,
        width: 300,
      },
    },
    to: {
      DEFAULT: {
        x: 35.5,
        y: 13.2,
        height: 149,
        width: 149,
      },
      lg: {
        x: 39.1,
        y: 13.7,
        height: 149,
        width: 149,
      },
      xl: {
        x: 39.1,
        y: 13.7,
        height: 300,
        width: 300,
      },
    },
    exit: {
      DEFAULT: {
        x: 35.5,
        y: -91,
        height: 157,
        width: 157,
      },
      lg: {
        x: 39,
        y: -83,
        height: 157,
        width: 157,
      },
      xl: {
        x: 39,
        y: -83,
        height: 300,
        width: 300,
      },
    },
  },
  // Interpolis
  {
    from: {
      DEFAULT: {
        x: 56,
        y: 150,
        width: 173,
        height: 173,
      },
      lg: {
        x: 101,
        y: 49,
        width: 173,
        height: 173,
      },
      xl: {
        x: 101,
        y: 49,
        width: 300,
        height: 300,
      },
    },
    to: {
      DEFAULT: {
        x: 59.2,
        y: 57,
        width: 141,
        height: 141,
      },
      lg: {
        x: 73,
        y: 45,
        width: 141,
        height: 141,
      },
      xl: {
        x: 73,
        y: 45,
        width: 300,
        height: 300,
      },
    },
    exit: {
      DEFAULT: {
        x: 61,
        y: -49,
        width: 151,
        height: 151,
      },
      lg: {
        x: 73,
        y: -56,
        width: 151,
        height: 151,
      },
      xl: {
        x: 73,
        y: -56,
        width: 300,
        height: 300,
      },
    },
  },
  // FBTO
  {
    from: {
      DEFAULT: {
        x: 2.7,
        y: 155,
        width: 97,
        height: 97,
      },
      lg: {
        x: -17,
        y: 19.3,
        width: 97,
        height: 97,
      },
      xl: {
        x: -17,
        y: 19.3,
        width: 175,
        height: 175,
      },
    },
    to: {
      DEFAULT: {
        x: 4.8,
        y: 29.1,
        width: 88,
        height: 88,
      },
      lg: {
        x: 9.2,
        y: 36.5,
        width: 88,
        height: 88,
      },
      xl: {
        x: 9.2,
        y: 36.5,
        width: 175,
        height: 175,
      },
    },
    exit: {
      DEFAULT: {
        x: 4.8,
        y: -72.5,
        width: 88,
        height: 88,
      },
      lg: {
        x: 9.2,
        y: -88,
        width: 88,
        height: 88,
      },
      xl: {
        x: 9.2,
        y: -88,
        width: 175,
        height: 175,
      },
    },
  },
  // Inshared
  {
    from: {
      DEFAULT: {
        x: 74.4,
        y: 121.5,
        width: 81,
        height: 81,
      },
      lg: {
        x: 89.4,
        y: -24,
        width: 81,
        height: 81,
      },
      xl: {
        x: 89.4,
        y: -24,
        width: 175,
        height: 175,
      },
    },
    to: {
      DEFAULT: {
        x: 72,
        y: 28.2,
        width: 94,
        height: 94,
      },
      lg: {
        x: 67.1,
        y: 21,
        width: 94,
        height: 94,
      },
      xl: {
        x: 67.1,
        y: 21,
        width: 175,
        height: 175,
      },
    },
    exit: {
      DEFAULT: {
        x: 72,
        y: -45,
        width: 94,
        height: 94,
      },
      lg: {
        x: 67.1,
        y: -124,
        width: 94,
        height: 94,
      },
      xl: {
        x: 67.1,
        y: -124,
        width: 175,
        height: 175,
      },
    },
  },
  // De christelijke zorgverzekeraar
  {
    from: {
      DEFAULT: {
        x: 51.2,
        y: 212,
        width: 114,
        height: 114,
      },
      lg: {
        x: 57,
        y: 102.4,
        width: 114,
        height: 114,
      },
      xl: {
        x: 57,
        y: 102.4,
        width: 200,
        height: 200,
      },
    },
    to: {
      DEFAULT: {
        x: 43.2,
        y: 80,
        width: 119,
        height: 119,
      },
      lg: {
        x: 54.1,
        y: 64.3,
        width: 119,
        height: 119,
      },
      xl: {
        x: 54.1,
        y: 64.3,
        width: 200,
        height: 200,
      },
    },
    exit: {
      DEFAULT: {
        x: 45.6,
        y: -22,
        width: 119,
        height: 119,
      },
      lg: {
        x: 54.1,
        y: -51.1,
        width: 119,
        height: 119,
      },
      xl: {
        x: 54.1,
        y: -51.1,
        width: 200,
        height: 200,
      },
    },
  },
  // Zilveren Kruis
  {
    from: {
      DEFAULT: {
        x: 7.5,
        y: 115.4,
        width: 137,
        height: 137,
      },
      lg: {
        x: 5.4,
        y: 105,
        width: 137,
        height: 137,
      },
      xl: {
        x: 5.4,
        y: 105,
        width: 300,
        height: 300,
      },
    },
    to: {
      DEFAULT: {
        x: 9.3,
        y: 59.1,
        width: 141,
        height: 141,
      },
      lg: {
        x: 18.5,
        y: 62,
        width: 141,
        height: 141,
      },
      xl: {
        x: 18.5,
        y: 62,
        width: 300,
        height: 300,
      },
    },
    exit: {
      DEFAULT: {
        x: 9.3,
        y: -48.5,
        width: 159,
        height: 159,
      },
      lg: {
        x: 18.5,
        y: -102.6,
        width: 159,
        height: 159,
      },
      xl: {
        x: 18.5,
        y: -102.6,
        width: 300,
        height: 300,
      },
    },
  },
  // De friesland
  {
    from: {
      DEFAULT: {
        x: 7.7,
        y: 196,
        width: 102,
        height: 102,
      },
      lg: {
        x: 37.6,
        y: 121.1,
        width: 102,
        height: 102,
      },
      xl: {
        x: 37.6,
        y: 121.1,
        width: 175,
        height: 175,
      },
    },
    to: {
      DEFAULT: {
        x: 7.7,
        y: 83.5,
        width: 94,
        height: 94,
      },
      lg: {
        x: 41.2,
        y: 76.6,
        width: 94,
        height: 94,
      },
      xl: {
        x: 41.2,
        y: 76.6,
        width: 175,
        height: 175,
      },
    },
    exit: {
      DEFAULT: {
        x: 7.7,
        y: -26,
        width: 94,
        height: 94,
      },
      lg: {
        x: 41.2,
        y: -73,
        width: 94,
        height: 94,
      },
      xl: {
        x: 41.2,
        y: -73,
        width: 175,
        height: 175,
      },
    },
  },
];

export const getScrollLinkedImagesOverlayProps = (logos: AnimatedLogos[]) => {
  const overlayImages = logos.map((logo) => logo.fields);

  return {
    images: overlayImages.map((overlayImage, index) => ({
      id: `${index + 1}`,
      image: overlayImage.Image,
      link: overlayImage.LinkTarget,
      from: scrollLinkedImagesOverlayPositions[index].from,
      to: scrollLinkedImagesOverlayPositions[index].to,
      exit: scrollLinkedImagesOverlayPositions[index].exit,
    })),
  } as OverlayImagesCompositionProps;
};
