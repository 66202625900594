import { MotionValue } from 'framer-motion';
import React from 'react';
import ScrollLinkedMoveContentLayer, {
  MoveContentMotionPath,
} from './ScrollLinkedMoveContentLayer';
import { ViewportHeight } from '@achmea/sitecore-components/src/types';
import ScrollContainer from '../ScrollContainer/ScrollContainer';

export interface MoveContentCompositionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Path the content will animate through on the screen.
   */
  motionPath: MoveContentMotionPath;
  /**
   * Decides if the content should fade in between `from` and `to` coordinates
   */
  fadeIn?: boolean;
  /**
   * Decides if the content should fade out between `to` and `exit` coordinates
   */
  fadeOut?: boolean;
  /**
   * Name for registering in the scrollytelling provider.
   */
  name?: string;
  /**
   * Passing a `progress` value makes this component "controlled" by an outer
   * component (which could be another `ScrollContainer`).  This allows you to
   * render scrollytelling blocks simultaneously.  In this case, `scrollDuration`
   * and `scrollStartOffset` are ignored.
   */
  progress?: MotionValue<number>;
  /**
   * @default
   * [
   *  0, // Start moving content `from` bellow screen `to` its center
   *  0.25, // End moving content to screen center
   *  0.75, // Start moving content from screens center to `exit` above it
   *  1 // End moving content to above screen
   * ]
   */
  keyframes?: number[];
  /**
   * Amount of viewport heights this block will stay sticky for.  Defaults to 1.
   */
  scrollDuration?: ViewportHeight;
  /**
   * Determines the range between which the scroll animation takes effect.  For
   * example, passing [0.5, 1] will start the scroll animation after scrolling
   * through 50% of the block.  Default to [0, 1].
   */
  scrollEffectRange?: [number, number];
  /**
   * Offset (in viewport heights).  For example, passing -0.5 will start the
   * scroll animation when the block is visible halfway.
   */
  scrollStartOffset?: ViewportHeight;
}

const MoveContentComposition = (props: MoveContentCompositionProps) => {
  const {
    motionPath,
    fadeIn = false,
    fadeOut = false,
    name = 'Move content composition',
    progress,
    keyframes = [0, 0.25, 0.75, 1],
    scrollDuration = 1,
    scrollEffectRange = [0, 1],
    scrollStartOffset = 0,
    className = '',
    children,
    ...restProps
  } = props;

  return (
    <ScrollContainer
      progress={progress}
      scrollDuration={scrollDuration}
      scrollEffectRange={scrollEffectRange}
      scrollStartOffset={scrollStartOffset}
      className={`move-content-composition ${className}`}
      {...restProps}
    >
      {({ progress }) => (
        <ScrollLinkedMoveContentLayer
          progress={progress}
          keyframes={keyframes}
          motionPath={motionPath}
          fadeIn={fadeIn}
          fadeOut={fadeOut}
        >
          {children}
        </ScrollLinkedMoveContentLayer>
      )}
    </ScrollContainer>
  );
};

export default MoveContentComposition;
