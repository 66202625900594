import { MotionValue, motion, useTransform } from 'framer-motion';
import React from 'react';

export interface ScrollLinkedSecondDecorationsLayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  progress: MotionValue<number>;
  keyframes: number[];
}

const useFirstContainerMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '-40vmax',
    '-40vmax',
    '-68vmax',
    '-68vmax',
    '-150vmax',
    '-150vmax',
    '-150vmax',
  ]);

  const width = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '122vmax',
    '122vmax',
    '122vmax',
    '122vmax',
    '122vmax',
    '122vmax',
    '122vmax',
    '122vmax',
  ]);

  const display = useTransform(width, (value) =>
    value === '0vmax' ? 'none' : 'block',
  );

  return { y, width, display };
};

const useSecondDecorationMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '-45vmax',
    '-45vmax',
    '-60vmax',
    '-60vmax',
    '-150vmax',
    '-150vmax',
    '-150vmax',
  ]);

  const width = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '152vmax',
    '152vmax',
    '152vmax',
    '152vmax',
    '152vmax',
    '152vmax',
    '152vmax',
    '152vmax',
  ]);

  const display = useTransform(width, (value) =>
    value === '0vmax' ? 'none' : 'block',
  );

  return { y, width, display };
};

const ScrollLinkedSecondDecorationsLayer = (
  props: ScrollLinkedSecondDecorationsLayerProps,
) => {
  const { progress, keyframes, className = '', ...restProps } = props;

  if (keyframes.length !== 14) {
    throw new Error(
      `Length of ${ScrollLinkedSecondDecorationsLayer.name} keyframes does not match output animation length`,
    );
  }

  const {
    y: firstY,
    width: firstWidth,
    display: firstDisplay,
  } = useFirstContainerMoveTransform(progress, keyframes);
  const {
    y: secondY,
    width: secondWidth,
    display: secondDisplay,
  } = useSecondDecorationMoveTransform(progress, keyframes);

  return (
    <div
      className={`scroll-page-background-composition__second-decorations ${className}`}
      {...restProps}
    >
      <motion.div className="scroll-page-background-composition__second-decorations-container">
        <motion.div style={{ y: firstY }}>
          <motion.div
            className="scroll-page-background-composition__decoration-first"
            style={{ width: firstWidth, display: firstDisplay }}
          />
        </motion.div>
        <motion.div style={{ y: secondY }}>
          <motion.div
            className="scroll-page-background-composition__decoration-second"
            style={{ width: secondWidth, display: secondDisplay }}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ScrollLinkedSecondDecorationsLayer;
