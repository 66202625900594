import React, { useRef } from 'react';
import { MotionValue, useScroll, useTransform } from 'framer-motion';

type ViewportHeight = number;

export interface ScrollContainerProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  /**
   * Passing a `progress` value makes this component "controlled" by an outer
   * component (which could be another `ScrollContainer`).  This allows you to
   * render scrollytelling blocks simultaneously.  In this case, `scrollDuration`
   * and `scrollStartOffset` are ignored.
   */
  progress?: MotionValue<number>;
  /**
   * Amount of viewport heights this container will stay sticky for.  Use this
   * to control the "length" or "speed" of the scroll-linked animation.
   * Defaults to 1 (resulting in a total height of 200vh).
   */
  scrollDuration?: ViewportHeight;
  /**
   * Determines the range between which the scroll animation takes effect.  For
   * example, passing [0.5, 1] will start the scroll animation after scrolling
   * through 50% of the block.  Default to [0, 1].
   */
  scrollEffectRange?: [number, number];
  /**
   * Offset (in viewport heights).  For example, passing -0.5 will start the
   * scroll animation when the block is visible halfway.
   */
  scrollStartOffset?: ViewportHeight;
  children:
    | React.ReactNode
    | ((values: { progress: MotionValue<number> }) => React.ReactNode);
}

const ScrollContainer = (props: ScrollContainerProps) => {
  const {
    progress: externalProgress,
    scrollDuration = 1,
    scrollEffectRange = [0, 1],
    scrollStartOffset = 0,
    className = '',
    children,
    ...restProps
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [`${scrollStartOffset * 100}vh start`, 'end end'],
  });
  const progress = useTransform(
    externalProgress || scrollYProgress,
    scrollEffectRange,
    [0, 1],
  );

  if (externalProgress) {
    return (
      <div
        className={`scroll-container scroll-container_stacked ${className}`}
        {...restProps}
      >
        {typeof children === 'function' ? children({ progress }) : children}
      </div>
    );
  }

  return (
    <div
      ref={ref}
      style={{
        height: `${(scrollDuration + 1) * 100}vh`,
      }}
    >
      <div className={`scroll-container__content ${className}`} {...restProps}>
        {typeof children === 'function' ? children({ progress }) : children}
      </div>
    </div>
  );
};

ScrollContainer.displayName = 'ScrollContainer';

export default ScrollContainer;
