import React from 'react';
import {
  HTMLMotionProps,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';

const containerVariants = {
  hidden: {
    y: 300,
    x: 300,
  },
  show: {
    y: 0,
    x: 0,
    transition: {
      duration: 1,
    },
  },
};

export interface ScrollLinkedHeroDecorationsLayerProps
  extends HTMLMotionProps<'div'> {
  progress: MotionValue<number>;
  keyframes: number[];
}

const useMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(
    progress,
    keyframes,
    [0, -250, -250, -250, -250, -250, -250, -250, -250, -250],
  );
  return { y };
};

const ScrollLinkedHeroDecorationsLayer = (
  props: ScrollLinkedHeroDecorationsLayerProps,
) => {
  const { progress, keyframes, className = '', ...restProps } = props;

  if (keyframes.length !== 10) {
    throw new Error(
      `Length of ${ScrollLinkedHeroDecorationsLayer.name} keyframes does not match output animation length`,
    );
  }

  const { y } = useMoveTransform(progress, keyframes);

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="show"
      transition={{ duration: 2 }}
      className={`scroll-page-background-composition__hero-decorations ${className}`}
      {...restProps}
    >
      {/* Scroll animation needs to be on different div. Because if a user starts scrolling while the initial animation is still playing, it would cause for jittering behaviour. */}
      <motion.div
        className="scroll-page-background-composition__hero-decorations-container"
        style={{ y }}
      >
        <div className="scroll-page-background-composition__decoration-first" />
        <div className="scroll-page-background-composition__decoration-second" />
      </motion.div>
    </motion.div>
  );
};

export default ScrollLinkedHeroDecorationsLayer;
