import React from 'react';
import { MotionValue } from 'framer-motion';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { ViewportHeight } from '@achmea/sitecore-components/src/types';
import ScrollLinkedPageBackgroundLayer from './ScrollLinkedPageBackgroundLayer';
import ScrollLinkedFirstDecorationsLayer from './ScrollLinkedFirstDecorationsLayer';
import ScrollLinkedSecondDecorationsLayer from './ScrollLinkedSecondDecorationsLayer';
import ScrollLinkedThirdDecorationsLayer from './ScrollLinkedThirdDecorationsLayer';
import { PageBackgroundCompositionVideoButton } from './ScrollLinkedButtonsLayer';

export interface PageBackgroundCompositionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  videoUrl?: string;
  videoButton?: PageBackgroundCompositionVideoButton;
  /**
   * Passing a `progress` value makes this component "controlled" by an outer
   * component (which could be another `ScrollContainer`).  This allows you to
   * render scrollytelling blocks simultaneously.  In this case, `scrollDuration`
   * and `scrollStartOffset` are ignored.
   */
  progress?: MotionValue<number>;
  keyframes?: {
    decorations?: number[];
    background?: number[];
  };
  /**
   * Amount of viewport heights this block will stay sticky for.  Defaults to 1.
   */
  scrollDuration?: ViewportHeight;
  /**
   * Determines the range between which the scroll animation takes effect.  For
   * example, passing [0.5, 1] will start the scroll animation after scrolling
   * through 50% of the block.  Default to [0, 1].
   */
  scrollEffectRange?: [number, number];
  /**
   * Offset (in viewport heights).  For example, passing -0.5 will start the
   * scroll animation when the block is visible halfway.
   */
  scrollStartOffset?: ViewportHeight;
  /**
   * Callback function when the "next" button, rendered as a "scroll down" icon,
   * is clicked.
   */
  onClickNext?: () => void;
}

const PageBackgroundComposition = (props: PageBackgroundCompositionProps) => {
  const {
    videoUrl,
    videoButton,
    progress,
    keyframes = {},
    scrollDuration = 1,
    scrollEffectRange = [0, 1],
    scrollStartOffset = 0,
    onClickNext,
    className = '',
    ...restProps
  } = props;

  const {
    decorations: decorationsKeyframes = Array(14).map(
      (_, index) => (1 / 14) * index,
    ),
    background: backgroundKeyframes = [
      0.06, 0.12, 0.51, 0.54, 0.57, 0.6, 0.72, 0.78, 0.94, 0.97,
    ],
  } = keyframes;

  return (
    <ScrollContainer
      progress={progress}
      scrollDuration={scrollDuration}
      scrollEffectRange={scrollEffectRange}
      scrollStartOffset={scrollStartOffset}
      className={`scroll-page-background-composition ${className}`}
      {...restProps}
    >
      {({ progress }) => (
        <>
          <ScrollLinkedPageBackgroundLayer
            progress={progress}
            videoUrl={videoUrl}
            videoButton={videoButton}
            keyframes={backgroundKeyframes}
            onClickNext={onClickNext}
          />
          <ScrollLinkedFirstDecorationsLayer
            progress={progress}
            keyframes={decorationsKeyframes}
          />
          <ScrollLinkedSecondDecorationsLayer
            progress={progress}
            keyframes={decorationsKeyframes}
          />
          <ScrollLinkedThirdDecorationsLayer
            progress={progress}
            keyframes={decorationsKeyframes}
          />
        </>
      )}
    </ScrollContainer>
  );
};

export default PageBackgroundComposition;
