import React from 'react';
import {
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { MotionValue } from 'framer-motion';
import { ViewportHeight } from '@achmea/sitecore-components/src/types';
import ScrollLinkedPageCarouselLayer from './ScrollLinkedPageCarouselLayer';
import { CarouselCardItemComponentProps } from '@achmea/sitecore-components/src/CarouselCardItem';
import { Carousel, CarouselCardItem } from '@achmea/ui';

export interface PageCarouselCompositionProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: TextField;
  items: CarouselCardItemComponentProps[];
  /**
   * Passing a `progress` value makes this component "controlled" by an outer
   * component (which could be another `ScrollContainer`).  This allows you to
   * render scrollytelling blocks simultaneously.  In this case, `scrollDuration`
   * and `scrollStartOffset` are ignored.
   */
  progress?: MotionValue<number>;
  /**
   * Keyframes for the animation.
   */
  keyframes?: number[];
  /**
   * Amount of viewport heights this block will stay sticky for.  Defaults to 1.
   */
  scrollDuration?: ViewportHeight;
  /**
   * Determines the range between which the scroll animation takes effect.  For
   * example, passing [0.5, 1] will start the scroll animation after scrolling
   * through 50% of the block.  Default to [0, 1].
   */
  scrollEffectRange?: [number, number];
  /**
   * Offset (in viewport heights).  For example, passing -0.5 will start the
   * scroll animation when the block is visible halfway.
   */
  scrollStartOffset?: ViewportHeight;
}

const PageCarouselComposition = (props: PageCarouselCompositionProps) => {
  const {
    title,
    items,
    progress,
    scrollDuration = 1,
    keyframes = [0.2, 1],
    scrollEffectRange = [0, 1],
    scrollStartOffset = 0,
    className = '',
    ...restProps
  } = props;

  const { sitecoreContext } = useSitecoreContext();
  const isEditingInSitecore = sitecoreContext && sitecoreContext.pageEditing;

  return (
    <ScrollContainer
      progress={progress}
      scrollDuration={scrollDuration}
      scrollEffectRange={scrollEffectRange}
      scrollStartOffset={scrollStartOffset}
      className={`scroll-page-carousel-composition ${className}`}
      {...restProps}
    >
      {({ progress }) => (
        <ScrollLinkedPageCarouselLayer
          progress={progress}
          title={title}
          keyframes={keyframes}
          isEditingInSitecore={isEditingInSitecore || false}
        >
          {items.map((item: CarouselCardItemComponentProps, index) => (
            <Carousel.Item key={index}>
              <CarouselCardItem fields={item.fields} />
            </Carousel.Item>
          ))}
        </ScrollLinkedPageCarouselLayer>
      )}
    </ScrollContainer>
  );
};

export default PageCarouselComposition;
