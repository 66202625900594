import React from 'react';
import { Carousel } from '@achmea/ui';
import {
  HTMLMotionProps,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';
import { Text, TextField } from '@sitecore-jss/sitecore-jss-react';

const useScrollLinkedPageCarouselTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, ['100vh', '0vh']);

  return { y };
};

export interface ScrollPageCarouselLayerProps
  extends Omit<HTMLMotionProps<'div'>, 'title'> {
  progress: MotionValue<number>;
  title?: TextField;
  children: React.ReactNode;
  keyframes: number[];
  isEditingInSitecore: boolean;
}

const ScrollLinkedPageCarouselLayer = (props: ScrollPageCarouselLayerProps) => {
  const {
    progress,
    title,
    isEditingInSitecore,
    keyframes,
    children,
    className = '',
    style,
    ...restProps
  } = props;

  if (keyframes.length !== 2) {
    throw new Error(
      'Length of ScrollPageCarouselLayer keyframes does not match output animation length',
    );
  }

  const { y } = useScrollLinkedPageCarouselTransform(progress, keyframes);

  return (
    <motion.div
      className={`container_normal ${className}`}
      style={{ ...style, y }}
      {...restProps}
    >
      <Text
        tag="h2"
        field={title}
        className="heading-2 mb-3 sm:mb-4 xl:mb-12"
      />
      {isEditingInSitecore ? (
        <div className="scroll-page-carousel-composition__sitecore-ee-wrapper">
          {children}
        </div>
      ) : (
        <Carousel variant="navigation-position-bottom">{children}</Carousel>
      )}
    </motion.div>
  );
};

export default ScrollLinkedPageCarouselLayer;
