import React from 'react';
import { MotionValue, motion, useTransform, Variants } from 'framer-motion';
import {
  ArrowDownWideIcon,
  ExternalLinkIcon,
  PauseIcon,
  PlayIcon,
  RoutableSitecoreLink,
  useIsFieldValid,
} from '@achmea/ui';
import { useTranslation } from 'react-i18next';
import { LinkField, TextField } from '@sitecore-jss/sitecore-jss-react';

export interface PageBackgroundCompositionVideoButton {
  title: TextField;
  url: LinkField;
}

export interface ScrollPageBackgroundLayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  progress: MotionValue<number>;
  keyframes: number[];
  isVideoPaused: boolean;
  handlePauseVideo: () => void;
  videoButton?: PageBackgroundCompositionVideoButton;
  /**
   * Callback function when the "next" button, rendered as a "scroll down" icon,
   * is clicked.
   */
  onClickNext?: () => void;
}

const PAUSE_BUTTON_VARIANTS = {
  hidden: { y: '150%' },
  show: {
    y: '0%',
    transition: { duration: 1 },
  },
};

const PAUSE_BUTTON_VARIANTS_MD = {
  hidden: { x: '-150%' },
  show: {
    x: '0%',
    transition: { duration: 1 },
  },
};

const SCROLL_DOWN_BUTTON_VARIANTS = {
  hidden: { y: '150%' },
  show: {
    y: '0%',
    transition: { duration: 1 },
  },
};

const SCROLL_DOWN_BUTTON_ICON_VARIANTS: Variants = {
  up: {
    y: -10,
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'easeInOut',
      duration: 0.75,
    },
  },
  down: { y: 10 },
};

const VIDEO_BUTTON_VARIANTS = {
  hidden: { y: '150%' },
  show: {
    y: '0%',
    transition: { duration: 1 },
  },
};

const VIDEO_BUTTON_VARIANTS_MD = {
  hidden: { x: '150%' },
  show: {
    x: '0%',
    transition: { duration: 1 },
  },
};

const useButtonsTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const opacity = useTransform(progress, keyframes, [1, 0]);
  const display = useTransform(opacity, (value) => (value > 0 ? '' : 'none'));
  return { opacity, display };
};

const ScrollLinkedButtonsLayer = (props: ScrollPageBackgroundLayerProps) => {
  const {
    progress,
    keyframes,
    isVideoPaused,
    handlePauseVideo,
    videoButton,
    onClickNext,
    className = '',
    ...restProps
  } = props;

  if (keyframes.length !== 2) {
    throw new Error(
      `Length of ${ScrollLinkedButtonsLayer.name} keyframes does not match output animation length`,
    );
  }

  const { opacity, display } = useButtonsTransform(progress, keyframes);

  const { t } = useTranslation();

  const { isTextFieldValid, isLinkFieldValid } = useIsFieldValid();

  const showVideoButton = videoButton && isLinkFieldValid(videoButton.url);

  const videoButtonTitle = videoButton &&
    isTextFieldValid(videoButton.title) && (
      <span>{videoButton.title.value}</span>
    );

  const videoLinkButtons = showVideoButton && (
    <>
      <motion.div
        variants={VIDEO_BUTTON_VARIANTS}
        initial="hidden"
        animate="show"
        className="scroll-page-background-composition__video-button"
        style={{ opacity, display }}
      >
        <RoutableSitecoreLink
          field={videoButton.url}
          target="_blank"
          className="scroll-page-background-composition__video-button-link noindex"
        >
          {videoButtonTitle}
          <ExternalLinkIcon />
        </RoutableSitecoreLink>
      </motion.div>
      <motion.div
        variants={VIDEO_BUTTON_VARIANTS_MD}
        initial="hidden"
        animate="show"
        className="scroll-page-background-composition__video-button scroll-page-background-composition__video-button_md"
        style={{ opacity, display }}
      >
        <RoutableSitecoreLink
          field={videoButton.url}
          target="_blank"
          className="scroll-page-background-composition__video-button-link noindex"
        >
          {videoButtonTitle}
          <ExternalLinkIcon />
        </RoutableSitecoreLink>
      </motion.div>
    </>
  );

  return (
    <div
      className={`scroll-page-background-composition__buttons-container ${className}`}
      {...restProps}
    >
      <div className="scroll-page-background-composition__button-wrapper">
        <motion.button
          aria-label={`${t(isVideoPaused ? 'play-video' : 'pause-video')}`}
          variants={PAUSE_BUTTON_VARIANTS}
          initial="hidden"
          animate="show"
          className="scroll-page-background-composition__pause-button"
          style={{ opacity, display }}
          onClick={handlePauseVideo}
        >
          <span>{t(isVideoPaused ? 'play-video' : 'pause-video')}</span>{' '}
          {isVideoPaused ? <PlayIcon /> : <PauseIcon />}
        </motion.button>
        <motion.button
          aria-label={`${t(isVideoPaused ? 'play-video' : 'pause-video')}`}
          variants={PAUSE_BUTTON_VARIANTS_MD}
          initial="hidden"
          animate="show"
          className="scroll-page-background-composition__pause-button scroll-page-background-composition__pause-button_md"
          style={{ opacity, display }}
          onClick={handlePauseVideo}
        >
          <span className="noindex">{t(isVideoPaused ? 'play-video' : 'pause-video')}</span>{' '}
          {isVideoPaused ? <PlayIcon /> : <PauseIcon />}
        </motion.button>
      </div>
      <div className="scroll-page-background-composition__button-wrapper">
        <motion.button
          aria-label={`${t('scroll-down')}`}
          variants={SCROLL_DOWN_BUTTON_VARIANTS}
          initial="hidden"
          animate="show"
          className="scroll-page-background-composition__scroll-down-button"
          style={{ opacity, display }}
          onClick={() => {
            if (typeof onClickNext === 'function') {
              onClickNext();
            }
          }}
        >
          <motion.div
            variants={SCROLL_DOWN_BUTTON_ICON_VARIANTS}
            initial="down"
            animate="up"
          >
            <ArrowDownWideIcon className="scroll-page-background-composition__scroll-down-button-icon" />
          </motion.div>
        </motion.button>
      </div>
      <div className="scroll-page-background-composition__button-wrapper">
        {videoLinkButtons}
      </div>
    </div>
  );
};

export default ScrollLinkedButtonsLayer;
