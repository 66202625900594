import React, { useState } from 'react';
import {
  MotionValue,
  motion,
  useMotionTemplate,
  useTransform,
} from 'framer-motion';
import ScrollLinkedButtonsLayer, {
  PageBackgroundCompositionVideoButton,
} from './ScrollLinkedButtonsLayer';
import ScrollLinkedHeroDecorationsLayer from './ScrollLinkedHeroDecorationsLayer';

export interface ScrollPageBackgroundLayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  progress: MotionValue<number>;
  keyframes: number[];
  videoUrl?: string;
  videoButton?: PageBackgroundCompositionVideoButton;
  /**
   * Callback function when the "next" button, rendered as a "scroll down" icon,
   * is clicked.
   */
  onClickNext?: () => void;
}

const useBackgroundTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const backgroundZIndex = useTransform(
    progress,
    keyframes,
    [0, 0, 0, 0, 0, 0, 0, 11, 11, 11],
  );

  const backgroundX = useTransform(
    progress,
    keyframes,
    Array.from({ length: keyframes.length }, () => '-50%'),
  );

  const backgroundY = useTransform(progress, keyframes, [
    '0%',
    '0%',
    '0%',
    '0%',
    '0%',
    '0%',
    '100%',
    '100%',
    '50%',
    '0%',
  ]);

  const lightBackgroundTranslateX = useTransform(
    progress,
    keyframes,
    Array.from({ length: keyframes.length }, () => '-50%'),
  );

  const lightBackgroundTranslateY = useTransform(progress, keyframes, [
    '100vh',
    '100vh',
    '100vh',
    '-0vh',
    '-100vh',
    '-100vh',
    '-100vh',
    '-100vh',
    '-100vh',
    '-100vh',
  ]);

  return {
    backgroundY,
    backgroundX,
    backgroundZIndex,
    lightBackgroundTranslateX,
    lightBackgroundTranslateY,
  };
};

const useVideoTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const radius = useTransform(
    progress,
    keyframes,
    [250, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  );
  const clipPathY = useTransform(radius, [150, 0], ['50%', '100%']);

  const clipPath = useMotionTemplate`circle(${radius}vmin at 50% ${clipPathY})`;

  return { clipPath, radius };
};

const ScrollLinkedPageBackgroundLayer = (
  props: ScrollPageBackgroundLayerProps,
) => {
  const {
    progress,
    keyframes,
    videoUrl,
    videoButton,
    onClickNext,
    className = '',
    ...restProps
  } = props;

  if (keyframes.length !== 10) {
    throw new Error(
      `Length of ${ScrollLinkedPageBackgroundLayer.name} keyframes does not match output animation length`,
    );
  }

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isVideoPaused, setIsVideoPaused] = useState(
    Boolean(videoRef.current?.paused),
  );

  const handlePauseVideo = () => {
    const video = videoRef.current;

    if (video) {
      if (video.paused) {
        video.play();
        setIsVideoPaused(false);
      } else {
        video.pause();
        setIsVideoPaused(true);
      }
    }
  };

  const {
    backgroundY,
    backgroundZIndex,
    backgroundX,
    lightBackgroundTranslateX,
    lightBackgroundTranslateY,
  } = useBackgroundTransform(progress, keyframes);

  const { clipPath, radius } = useVideoTransform(progress, keyframes);

  return (
    <div
      className={`scroll-page-background-composition__background-container ${className}`}
      {...restProps}
    >
      <motion.div
        className="scroll-page-background-composition__video-container"
        style={{ clipPath }}
      >
        {videoUrl && (
          <video
            ref={videoRef}
            src={videoUrl}
            className="scroll-page-background-composition__video"
            autoPlay
            muted
            loop
          />
        )}
        <ScrollLinkedHeroDecorationsLayer
          progress={progress}
          keyframes={keyframes}
        />
      </motion.div>
      <ScrollLinkedButtonsLayer
        progress={radius}
        keyframes={[70, 0]}
        videoButton={videoButton}
        isVideoPaused={isVideoPaused}
        handlePauseVideo={handlePauseVideo}
        onClickNext={onClickNext}
      />
      <motion.div
        className="scroll-page-background-composition__background"
        style={{ x: backgroundX, y: backgroundY, zIndex: backgroundZIndex }}
      />
      <motion.div
        className="scroll-page-background-composition__light-background"
        style={{
          x: lightBackgroundTranslateX,
          y: lightBackgroundTranslateY,
        }}
      />
    </div>
  );
};

export default ScrollLinkedPageBackgroundLayer;
