import React from 'react';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import { MotionValue } from 'framer-motion';
import { ViewportHeight } from '@achmea/sitecore-components/src/types';
import { TextField } from '@sitecore-jss/sitecore-jss-react';
import ScrollLinkedHeroTitleLayer from './ScrollLinkedHeroTitleLayer';
import ScrollLinkedHeroButtonLayer from './ScrollLinkedHeroButtonLayer';

interface HeroCompositionKeyframes {
  titleExit?: number[];
  buttonExit?: number[];
}

export interface HeroCompositionProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: TextField;
  /**
   * Passing a `progress` value makes this component "controlled" by an outer
   * component (which could be another `ScrollContainer`).  This allows you to
   * render scrollytelling blocks simultaneously.  In this case, `scrollDuration`
   * and `scrollStartOffset` are ignored.
   */
  progress?: MotionValue<number>;
  keyframes?: HeroCompositionKeyframes;
  /**
   * Amount of viewport heights this block will stay sticky for.  Defaults to 1.
   */
  scrollDuration?: ViewportHeight;
  /**
   * Determines the range between which the scroll animation takes effect.  For
   * example, passing [0.5, 1] will start the scroll animation after scrolling
   * through 50% of the block.  Default to [0, 1].
   */
  scrollEffectRange?: [number, number];
  /**
   * Offset (in viewport heights).  For example, passing -0.5 will start the
   * scroll animation when the block is visible halfway.
   */
  scrollStartOffset?: ViewportHeight;
  /**
   * Callback function when the "next" button, rendered as a "scroll down" icon,
   * is clicked.
   */
  onClickNext?: () => void;
}

const HeroComposition = (props: HeroCompositionProps) => {
  const {
    title,
    progress,
    keyframes = {},
    scrollEffectRange = [0, 1],
    scrollDuration = 1,
    scrollStartOffset = 0,
    onClickNext,
    className = '',
    ...restProps
  } = props;

  const {
    titleExit: titleExitKeyframes = [0.6, 1],
    buttonExit: buttonExitKeyframes = [0.6, 1],
  } = keyframes;

  return (
    <ScrollContainer
      progress={progress}
      scrollDuration={scrollDuration}
      scrollEffectRange={scrollEffectRange}
      scrollStartOffset={scrollStartOffset}
      className={`scroll-hero-composition ${className}`}
      {...restProps}
    >
      {({ progress }) => (
        <div className="scroll-hero-composition__text">
          {title && (
            <ScrollLinkedHeroTitleLayer
              title={title}
              progress={progress}
              keyframes={titleExitKeyframes}
            />
          )}
        </div>
      )}
    </ScrollContainer>
  );
};

export default HeroComposition;
