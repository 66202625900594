import React from 'react';
import {
  Placeholder,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';

import './assets/app.css';
import '@achmea/styles/src/index.scss';
import { usePageTheme, getThemeClassName } from '@achmea/ui';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const canonicalUrl =
    sitecoreContext && sitecoreContext.currentPage?.canonicalUrl;
  const siteSettings = sitecoreContext && sitecoreContext.siteSettings;

  const theme = usePageTheme();

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <body
          className={`${
            isEditing ? 'pagemode-editing' : ''
          } theme ${getThemeClassName(theme)}`}
        />

        {route?.fields?.MetaTitle?.value && (
          <title>
            {`${route.fields.MetaTitle.value}${
              siteSettings?.metaTitlePostfix || ''
            }`}
          </title>
        )}
        {route?.fields?.MetaDescription?.value && (
          <meta
            name="description"
            content={route.fields.MetaDescription.value}
          />
        )}

        <link rel="canonical" href={canonicalUrl} />
        {
          <meta
            name="robots"
            content={`${route.fields['Meta Robots Index'].fields['Meta Content'].value},${route.fields['Meta Robots Follow'].fields['Meta Content'].value}`}
          />
        }

        {(route?.fields?.OpenGraphTitle?.value && (
          <meta
            property="og:title"
            content={route.fields.OpenGraphTitle.value}
          />
        )) ||
          (route?.fields?.MetaTitle?.value && (
            <meta property="og:title" content={route.fields.MetaTitle.value} />
          ))}
        {(route?.fields?.OpenGraphDescription?.value && (
          <meta
            property="og:description"
            content={route.fields.OpenGraphDescription.value}
          />
        )) ||
          (route?.fields?.MetaDescription?.value && (
            <meta
              property="og:description"
              content={route.fields.MetaDescription.value}
            />
          ))}
        {route?.fields?.OpenGraphImage?.value.src && (
          <meta
            property="og:image"
            content={route.fields.OpenGraphImage.value.src}
          />
        )}
        {route?.fields?.OpenGraphType?.value && (
          <meta property="og:type" content={route.fields.OpenGraphType.value} />
        )}
        <meta property="og:url" content={canonicalUrl} />
        {siteSettings?.javaScriptTop && (
          <script type="text/javascript">{`${siteSettings.javaScriptTop}`}</script>
        )}
      </Helmet>

      {/* root placeholder for the app, which we add components to using route data */}
      <main className="site-main">
        <Placeholder name="route-main" rendering={route} />
      </main>
    </React.Fragment>
  );
};

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
