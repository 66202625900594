import React from 'react';
import { TextField, Text } from '@sitecore-jss/sitecore-jss-react';
import {
  HTMLMotionProps,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';

const TITLE_CONTAINER_VARIANTS_MD = {
  hidden: {
    opacity: 0,
    y: 'calc(66vh - 50%)',
  },
  show: {
    opacity: 1,
    y: 'calc(50vh - 50%)',
    transition: {
      duration: 1,
    },
  },
};

const TITLE_CONTAINER_VARIANTS = {
  hidden: {
    opacity: 0,
    y: 'calc(34vh - 50%)',
  },
  show: {
    opacity: 1,
    y: 'calc(25.8vh - 50%)',
    transition: {
      duration: 1,
    },
  },
};

const useScrollLinkedTitleExitTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, ['0', '-8.5vh']);
  const opacity = useTransform(progress, keyframes, [1, 0]);
  const display = useTransform(opacity, (value) => (value === 0 ? 'none' : ''));

  return {
    y,
    opacity,
    display,
  };
};

export interface ScrollLinkedTitleLayerProps
  extends Omit<HTMLMotionProps<'div'>, 'title'> {
  progress: MotionValue<number>;
  title: TextField;
  keyframes: number[];
}

const ScrollLinkedHeroTitleLayer = (props: ScrollLinkedTitleLayerProps) => {
  const {
    progress,
    title,
    keyframes,
    className = '',
    style,
    ...restProps
  } = props;

  if (keyframes.length !== 2) {
    throw new Error(
      `Length of ${ScrollLinkedHeroTitleLayer.name} keyframes does not match output animation length`,
    );
  }

  const { y, opacity, display } = useScrollLinkedTitleExitTransform(
    progress,
    keyframes,
  );

  const heroTitle = (
    <motion.h1
      className={`scroll-hero-composition__title ${className}`}
      style={{
        ...style,
        opacity,
        y,
      }}
      {...restProps}
    >
      <Text field={title} />
    </motion.h1>
  );

  return (
    <>
      <motion.div
        variants={TITLE_CONTAINER_VARIANTS_MD}
        initial="hidden"
        animate="show"
        className="scroll-hero-composition__title-container scroll-hero-composition__title-container_md"
        style={{ display }}
      >
        {heroTitle}
      </motion.div>
      <motion.div
        variants={TITLE_CONTAINER_VARIANTS}
        initial="hidden"
        animate="show"
        className="scroll-hero-composition__title-container"
        style={{ display }}
      >
        {heroTitle}
      </motion.div>
    </>
  );
};

export default ScrollLinkedHeroTitleLayer;
