import { MotionValue, motion, useTransform } from 'framer-motion';
import React from 'react';

export interface ScrollLinkedFirstDecorationsLayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  progress: MotionValue<number>;
  keyframes: number[];
}

const useContainerMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '165vmax',
    '30vmax',
    '30vmax',
    '0vmax',
    '0vmax',
    '-30vmax',
    '-30vmax',
    '-100vmax',
    '-100vmax',
    '-100vmax',
    '-100vmax',
    '-100vmax',
    '-100vmax',
    '-100vmax',
  ]);

  return { y };
};

const useDecorationMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '27.5vmax',
    '27.5vmax',
    '27.5vmax',
    '27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
    '-27.5vmax',
  ]);

  return { y };
};

const ScrollLinkedFirstDecorationsLayer = (
  props: ScrollLinkedFirstDecorationsLayerProps,
) => {
  const { progress, keyframes, className = '', ...restProps } = props;

  if (keyframes.length !== 14) {
    throw new Error(
      `Length of ${ScrollLinkedFirstDecorationsLayer.name} keyframes does not match output animation length`,
    );
  }

  const { y: containerY } = useContainerMoveTransform(progress, keyframes);
  const { y: decorationY } = useDecorationMoveTransform(progress, keyframes);

  return (
    <div
      className={`scroll-page-background-composition__first-decorations ${className}`}
      {...restProps}
    >
      <motion.div
        className="scroll-page-background-composition__first-decorations-container"
        style={{ y: containerY }}
      >
        <div className="scroll-page-background-composition__decoration-first" />
        <motion.div style={{ y: decorationY }}>
          <div className="scroll-page-background-composition__decoration-second" />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ScrollLinkedFirstDecorationsLayer;
