import { ResponsiveValue, useResponsiveValue } from '@achmea/ui';
import {
  HTMLMotionProps,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';
import React from 'react';

interface MotionPathCoordinates {
  x: number;
  y: number;
  /**
   * Shift position in percentage of the content's width
   * @default 0
   */
  shiftX?: number;
  /**
   * Shift position in percentage of the content's height
   * @default 0
   */
  shiftY?: number;
}

export interface MoveContentMotionPath {
  from: Partial<ResponsiveValue<MotionPathCoordinates>>;
  to: Partial<ResponsiveValue<MotionPathCoordinates>>;
  exit: Partial<ResponsiveValue<MotionPathCoordinates>>;
}

const useContentTransform = (
  motionPath: MoveContentMotionPath,
  progress: MotionValue<number>,
  keyframes: number[],
  fadeIn?: boolean,
  fadeOut?: boolean,
) => {
  const from = useResponsiveValue(motionPath.from);
  const to = useResponsiveValue(motionPath.to);
  const exit = useResponsiveValue(motionPath.exit);

  const translateX = useTransform(progress, keyframes, [
    `calc(${from.x}vw + ${from.shiftX || 0}%)`,
    `calc(${to.x}vw + ${to.shiftX || 0}%)`,
    `calc(${to.x}vw + ${to.shiftX || 0}%)`,
    `calc(${exit.x}vw + ${exit.shiftX || 0}%)`,
  ]);

  const translateY = useTransform(progress, keyframes, [
    `calc(${from.y}vh + ${from.shiftY || 0}%)`,
    `calc(${to.y}vh + ${to.shiftY || 0}%)`,
    `calc(${to.y}vh + ${to.shiftY || 0}%)`,
    `calc(${exit.y}vh + ${exit.shiftY || 0}%)`,
  ]);

  const startOpacity = fadeIn ? 0 : 1;
  const endOpacity = fadeOut ? 0 : 1;
  const opacityOutput = [startOpacity, 1, 1, endOpacity];
  const opacity = useTransform(progress, keyframes, opacityOutput);

  return { translateX, translateY, opacity };
};

export interface ScrollLinkedMoveContentLayerProps
  extends HTMLMotionProps<'div'> {
  progress: MotionValue<number>;
  keyframes: number[];
  motionPath: MoveContentMotionPath;
  fadeIn?: boolean;
  fadeOut?: boolean;
}

const ScrollLinkedMoveContentLayer = (
  props: ScrollLinkedMoveContentLayerProps,
) => {
  const {
    progress,
    keyframes,
    motionPath,
    fadeIn = true,
    fadeOut = true,
    className = '',
    style,
    children,
    ...restProps
  } = props;

  if (keyframes.length !== 4) {
    throw new Error(
      `Length of ${ScrollLinkedMoveContentLayer.name} keyframes does not match output animation length`,
    );
  }

  const { translateX, translateY, opacity } = useContentTransform(
    motionPath,
    progress,
    keyframes,
    fadeIn,
    fadeOut,
  );

  return (
    <motion.div
      className={`move-content-composition__layer ${className}`}
      style={{
        ...style,
        translateX,
        translateY,
        opacity,
      }}
      {...restProps}
    >
      {children}
    </motion.div>
  );
};

export default ScrollLinkedMoveContentLayer;
