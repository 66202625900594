import React from 'react';
import { MotionValue } from 'framer-motion';
import ScrollLinkedOverlayImageLayer, {
  OverlayImage,
} from './ScrollLinkedOverlayImageLayer';
import { ViewportHeight } from '@achmea/sitecore-components/src/types';
import ScrollContainer from '../ScrollContainer/ScrollContainer';
import ScrollLinkedOverlayImagesTitleLayer from './ScrollLinkedOverlayImagesTitleLayer';

export interface OverlayImagesCompositionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  images: OverlayImage[];
  /**
   * Passing a `progress` value makes this component "controlled" by an outer
   * component (which could be another `ScrollContainer`).  This allows you to
   * render scrollytelling blocks simultaneously.  In this case, `scrollDuration`
   * and `scrollStartOffset` are ignored.
   */
  progress?: MotionValue<number>;
  keyframes?: {
    title?: number[];
    overlayImage?: number[];
  };
  /**
   * Amount of viewport heights this block will stay sticky for.  Defaults to 1.
   */
  scrollDuration?: ViewportHeight;
  /**
   * Determines the range between which the scroll animation takes effect.  For
   * example, passing [0.5, 1] will start the scroll animation after scrolling
   * through 50% of the block.  Default to [0, 1].
   */
  scrollEffectRange?: [number, number];
  /**
   * Offset (in viewport heights).  For example, passing -0.5 will start the
   * scroll animation when the block is visible halfway.
   */
  scrollStartOffset?: ViewportHeight;
}

const OverlayImagesComposition = (props: OverlayImagesCompositionProps) => {
  const {
    progress,
    keyframes = {},
    scrollDuration = 1,
    scrollEffectRange = [0, 1],
    scrollStartOffset = 0,
    images,
    className = '',
    children,
    ...restProps
  } = props;

  const {
    title: titleKeyframes = [0, 0.2, 0.8, 1],
    overlayImage: overlayImageKeyframes = [0, 0.2, 0.8, 1],
  } = keyframes;

  return (
    <ScrollContainer
      progress={progress}
      scrollDuration={scrollDuration}
      scrollEffectRange={scrollEffectRange}
      scrollStartOffset={scrollStartOffset}
      className={`scroll-linked-overlay-images-composition ${className}`}
      {...restProps}
    >
      {({ progress }) => (
        <div className="scroll-linked-overlay-images-composition__text">
          <ScrollLinkedOverlayImagesTitleLayer
            progress={progress}
            keyframes={titleKeyframes}
          >
            {children}
          </ScrollLinkedOverlayImagesTitleLayer>
          <div className="scroll-linked-overlay-images-composition__images">
            {images.map((image) => (
              <ScrollLinkedOverlayImageLayer
                key={image.id}
                progress={progress}
                overlayImage={image}
                keyframes={overlayImageKeyframes}
              />
            ))}
          </div>
        </div>
      )}
    </ScrollContainer>
  );
};

export default OverlayImagesComposition;
