import { Range, Timeline } from './types';

export const getTimelineDuration = (timeline: Timeline) =>
  Object.entries(timeline).reduce((acc, [, [, end]]) => {
    return Math.max(acc, end);
  }, 0);

export const mapKeyframes = (
  keyframes: number[],
  sourceRange: Range = [0, 1],
  targetRange: Range = [0, 1],
) => {
  const [sourceStart, sourceEnd] = sourceRange;
  const [targetStart, targetEnd] = targetRange;
  const sourceLength = sourceEnd - sourceStart;
  const targetLength = targetEnd - targetStart;

  return keyframes.map(
    (keyframe) =>
      targetStart + ((keyframe - sourceStart) / sourceLength) * targetLength,
  );
};

export const mapTimelineToRange = (
  timeline: Timeline,
  sourceRange: Range = [0, 1],
  targetRange: Range = [0, 1],
): Record<string, Range> => {
  return Object.fromEntries(
    Object.entries(timeline).map(([name, range]) => {
      return [name, mapKeyframes(range, sourceRange, targetRange) as Range];
    }),
  );
};
