import {
  HTMLMotionProps,
  MotionValue,
  motion,
  useTransform,
} from 'framer-motion';
import React from 'react';
import { useBreakpoints } from '@achmea/ui';

export interface ScrollLinkedOverlayImagesTitleLayer
  extends HTMLMotionProps<'div'> {
  progress: MotionValue<number>;
  keyframes: number[];
}

const useTitleTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const { isXl } = useBreakpoints();

  const opacity = useTransform(progress, keyframes, [0, 1, 1, 1]);
  const translateY = useTransform(
    progress,
    keyframes,
    isXl
      ? ['32vh', '0vh', '0vh', '-92vh']
      : ['28vh', '0vh', '0vh', '-123vh'],
  );

  return { opacity, translateY };
};

const ScrollLinkedOverlayImagesTitleLayer = (
  props: ScrollLinkedOverlayImagesTitleLayer,
) => {
  const {
    progress,
    keyframes,
    style,
    children,
    className = '',
    ...restProps
  } = props;

  if (keyframes.length !== 4) {
    throw new Error(
      `Length of ${ScrollLinkedOverlayImagesTitleLayer.name} keyframes does not match output animation length`,
    );
  }

  const { opacity, translateY } = useTitleTransform(progress, keyframes);

  return (
    <motion.div
      className={`scroll-linked-overlay-images-composition__title ${className}`}
      style={{ ...style, opacity, translateY }}
      {...restProps}
    >
      {children}
    </motion.div>
  );
};

export default ScrollLinkedOverlayImagesTitleLayer;
