import { rest } from 'msw';

const uid = () => {
  return Math.random().toString(16).slice(2);
};

export const handlers = [
  rest.post('/apiproxy/TemplatedEmail/sendtemplatedemail', (req, res, ctx) => {
    return res(ctx.json({}));
  }),

  rest.post('/apiproxy/TemplatedEmail/UploadFile', (req, res, ctx) => {
    // If you want to mock this API with a real network response instead, you
    // can disable this handler and add the following to src/setupProxy.js:
    //
    // app.route('/apiproxy/TemplatedEmail/UploadFile').all((req, res) => {
    //   res.json({ attachmentId: 'test' });
    // });
    return res(ctx.json({ attachmentId: uid() }));
  }),
];
