import React from 'react';
import {
  ComponentRendering,
  RouteData,
  Field,
  LinkField,
  TextField,
  ImageField,
  ImageFieldValue,
} from '@sitecore-jss/sitecore-jss-react';
import {
  GenericFieldValue,
  Item,
} from '@sitecore-jss/sitecore-jss/types/layout/models';
import HomePageLayout from '../../features/pages/HomePage/HomePage';
import { CarouselCardItemComponentProps } from '@achmea/sitecore-components/src/CarouselCardItem';
import { CardComponentProps } from '@achmea/sitecore-components/src/Card';

interface ScrollableTextItemField {
  Title: TextField;
}

export interface ScrollableTextItem {
  fields: ScrollableTextItemField;
}

export interface AnimatedLogosField {
  Image: ImageField | ImageFieldValue;
  LinkTarget: LinkField;
}

export interface AnimatedLogos {
  fields: AnimatedLogosField;
}

export interface HomePageLayoutComponentFields {
  HeroTitle: TextField;
  FirstRoundedImage: ImageField | ImageFieldValue;
  SecondRoundedImage: ImageField | ImageFieldValue;
  ScrollableTextItems: ScrollableTextItem[];
  AnimatedTitle: TextField;
  AnimatedSubTitle: TextField;
  AnimatedLogoTitle: TextField;
  AnimatedLogos: AnimatedLogos[];
  VideoTarget: LinkField;
  CardSliderTitle: TextField;
  CardSliderItems: CardComponentProps[];
  VideoButtonTitle: TextField;
  VideoButtonTarget: LinkField;
  CarouselTitle: TextField;
  CarouselItems: CarouselCardItemComponentProps[];
}

export interface HomePageLayoutComponentProps {
  fields: HomePageLayoutComponentFields;
  rendering:
    | ComponentRendering
    | RouteData<Record<string, Field<GenericFieldValue> | Item | Item[]>>;
}

const HomePageLayoutComponent = ({
  fields,
  rendering,
}: HomePageLayoutComponentProps) => (
  <HomePageLayout fields={fields} rendering={rendering} />
);

export default HomePageLayoutComponent;
