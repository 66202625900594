import { MotionValue, motion, useTransform } from 'framer-motion';
import React from 'react';

export interface ScrollLinkedThirdDecorationsLayerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  progress: MotionValue<number>;
  keyframes: number[];
}

const useFirstContainerMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '40vmax',
    '40vmax',
    '40vmax',
  ]);

  const width = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '91vmax',
    '91vmax',
    '200vmax',
  ]);

  const display = useTransform(width, (value) =>
    value === '0vmax' ? 'none' : 'block',
  );

  return { y, width, display };
};

const useSecondDecorationMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '120vmax',
    '80vmax',
    '80vmax',
    '-35vmax',
  ]);

  const x = useTransform(progress, keyframes, [
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-10vmax',
    '-5vmax',
  ]);

  const width = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '120vmax',
    '177vmax',
    '177vmax',
    '116vmax',
  ]);

  const display = useTransform(width, (value) =>
    value === '0vmax' ? 'none' : 'block',
  );

  return { y, x, width, display };
};

const useThirdDecorationMoveTransform = (
  progress: MotionValue<number>,
  keyframes: number[],
) => {
  const y = useTransform(progress, keyframes, [
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '0vmax',
    '-80vmax',
  ]);

  const width = useTransform(y, (value) =>
    value === '0vmax' ? '0vmax' : '251vmax',
  );

  const display = useTransform(width, (value) =>
    value === '0vmax' ? 'none' : 'block',
  );

  return { y, width, display };
};

const ScrollLinkedThirdDecorationsLayer = (
  props: ScrollLinkedThirdDecorationsLayerProps,
) => {
  const { progress, keyframes, className = '', ...restProps } = props;

  if (keyframes.length !== 14) {
    throw new Error(
      `Length of ${ScrollLinkedThirdDecorationsLayer.name} keyframes does not match output animation length`,
    );
  }

  const {
    y: firstY,
    width: firstWidth,
    display: firstDisplay,
  } = useFirstContainerMoveTransform(progress, keyframes);

  const {
    y: secondY,
    x: secondX,
    width: secondWidth,
    display: secondDisplay,
  } = useSecondDecorationMoveTransform(progress, keyframes);

  const {
    y: thirdY,
    width: thirdWidth,
    display: thirdDisplay,
  } = useThirdDecorationMoveTransform(progress, keyframes);

  return (
    <div
      className={`scroll-page-background-composition__third-decorations ${className}`}
      {...restProps}
    >
      <motion.div className="scroll-page-background-composition__third-decorations-container">
        <motion.div style={{ y: firstY }}>
          <motion.div
            className="scroll-page-background-composition__decoration-first"
            style={{ width: firstWidth, display: firstDisplay }}
          />
        </motion.div>
        <motion.div style={{ x: secondX, y: secondY }}>
          <motion.div
            className="scroll-page-background-composition__decoration-second"
            style={{ width: secondWidth, display: secondDisplay }}
          />
        </motion.div>
        <motion.div style={{ y: thirdY }}>
          <motion.div
            className="scroll-page-background-composition__decoration-third"
            style={{ width: thirdWidth, display: thirdDisplay }}
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ScrollLinkedThirdDecorationsLayer;
